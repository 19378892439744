export const defaultLocation = {
  center: [37.625269177345636, 55.75275795047099],
  zoom: 7,
};

export const botRegExp =
  /Googlebot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver|Google-InspectionTool|Google-CloudVertexBot|Google-Other/i;

export const mobileDeviceRegExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;

export const YMAP_API_KEY = "5313ee7c-a09e-4694-92be-f2ff7218518b";

export const defaultNotificationsTabs = [
  {
    name: "Купон",
    code: "coupon",
    preview_picture: "/upload/iblock/150/8o0a4vuez2sm239pp8edu4kkt22841dm.webp",
    counts: "0",
  },
  {
    name: "Заказы",
    code: "order",
    preview_picture: "/upload/iblock/46a/ib5so83qb2arkpkizraepb8jh3dnrnen.webp",
    counts: "28",
  },
  {
    name: "Ответы на Вопросы",
    code: "question_answer",
    preview_picture: "/upload/logo-header.webp",
    counts: "0",
  },
  {
    name: "Комиссионка",
    code: "comission",
    preview_picture: "/upload/logo-header.webp",
    counts: "0",
  },
  {
    name: "Отзывы",
    code: "review",
    preview_picture: "/upload/logo-header.webp",
    counts: "0",
  },
  {
    name: "Акции",
    code: "sale",
    preview_picture: "/upload/iblock/ab6/z6tq3t99hk68zm0vlbedqe8iqnzl3myo.webp",
    counts: "0",
  },
];

export const profileMenuList = [
  {
    section: "personal-info",
    title: "Личные данные",
    link: "/cabinet/personal-data",
  },
  {
    section: "discount-card",
    title: "Дисконтная карта",
    link: "/cabinet/discount-card",
  },
  {
    section: "notifications",
    title: "Мои уведомления",
    link: "/cabinet/notifications",
  },
  {
    section: "history",
    title: "История просмотров",
    link: "/cabinet/history",
  },
  {
    section: "subscription",
    title: "Управление подпиской",
    link: "/cabinet/subscription",
  },
  {
    section: "ads",
    title: "Объявления",
    link: "/cabinet/ads",
  },
  {
    section: "orders",
    title: "Заказы",
    link: "/cabinet/orders",
  },
  {
    section: "purchase-history",
    title: "История покупок",
    link: "/cabinet/purchase-history",
  },
  {
    section: "favourites",
    title: "Избранное",
    link: "/cabinet/favourites",
  },
  {
    section: "reviews",
    title: "Отзывы",
    link: "/cabinet/reviews",
  },
];

export const supportNumber = "+7 (495) 121-41-14";

export const supportsNumberHref = "tel:84951214114";
